import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const VoorwaardenPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/home/hero.jpg" }) {
        ...heroImage
      }
      logo: file(relativePath: { eq: "images/logo.png" }) {
        ...logoImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home - Uytterhaegen Jan BV" pathname="/" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Uytterhaegen bvba</h2>
            <h3>Ervaring en professionele kwaliteit zijn ons handelsmerk</h3>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <h1>Algemene voorwaarden</h1>
              <ol>
                <li>
                  Aanvaarden van de voorwaarden Bij het plaatsen van zijn
                  bestelling aanvaardt de klant uitdrukkelijk deze algemene
                  voorwaarden en verzaakt hij aan eigen of enige andere
                  voorwaarde die niet door de BVBA Jan Uytterhaegen schriftelijk
                  goedgekeurd worden. Deze laatste zijn niet tegenstelbaar aan
                  de BVBA Jan Uytterhaegen en blijven derhalve zonder enige
                  uitwerking.
                </li>
                <li>
                  De BVBA Jan Uytterhaegen maakt een offerte voor de door de
                  klant gevraagde diensten en/of goederen en met vermelding van
                  de kostprijs. De vermelde kostprijzen op de offerte zijn
                  exclusief BTW. Bij akkoord ondertekent de klant de offerte en
                  bezorgt deze aan de BVBA Jan Uytterhaegen. De bestelling wordt
                  pas geldig na de ondertekening van de offerte. Meerwerken
                  worden pas uitgevoerd na bevestiging van klant via mail,
                  schriftelijk of door elk ander wettelijk bewijsmiddel.
                </li>
                <li>
                  In geval er factoren blijken te zijn die niet gekend of
                  meegedeeld waren bij de opmaak van de offerte, kan de BVBA Jan
                  Uytterhaegen de offerte wijzigen. Dat geldt zowel voor de
                  diensten als goederen. De bvba Jan Uytterhaegen houdt daarbij
                  zoveel mogelijk rekening met de belangen van de klant.
                </li>
                <li>
                  De klant dient er zorg voor te dragen dat voor de aanvang van
                  de werken de werf vlot bereikbaar en vrij is. Hij dient tevens
                  te zorgen voor afgifte op voorhand van de sleutel als de werf
                  gesloten is. De werf dient door de klant in het voordeel van
                  de aannemer gratis voorzien te worden van water en
                  elektriciteit.
                </li>
                <li>
                  Indien de klant de bestelling verbreekt of annuleert of indien
                  de bestelling/overeenkomst door zijn toedoen geen doorgang kan
                  vinden, is deze aan de BVBA Jan Uytterhaegen een
                  schadevergoeding verschuldigd van 10% van het totaalbedrag.
                </li>
                <li>
                  Klachten en/of bezwaren moet aangetekend worden geformuleerd
                  binnen de 5 dagen na de levering van de goederen en/of
                  ontvangst van de factuur.
                </li>
                <li>
                  De prijs wordt als volgt betaald:
                  <ol className="subList">
                    <li>30% voor de start van de werken</li>
                    <li>
                      50% wanneer de werken uitgevoerd door de BVBA Jan
                      Uytterhaegen klaar zijn voor de pleister werken, indien
                      deze van toepassing is.
                    </li>
                    <li>
                      20% voor de opstart van de installatie, zowel sanitair als
                      verwarming Bij gebreke aan betaling van een factuur op
                      haar vervaldag, zal de factuur van rechtswege en zonder
                      ingebrekestelling worden verhoogd met een forfaitaire
                      vergoeding van 10% met een minimum van 25€ en met een
                      verwijlintrest van 12% per jaar vanaf de vervaldag tot aan
                      de volledige betaling, ongeacht alle andere uitgaven en
                      gerechtskosten.
                    </li>
                  </ol>
                </li>
                <li>
                  Beding van eigendomsvoorbehoud. De BVBA Jan Uytterhaegen
                  blijft eigenaar van de goederen tot aan de volledige betaling
                  van de corresponderende facturen, eventueel verhoogd met
                  intresten. De klant staat tevens in voor schade aan en
                  vervreemding van ons materiaal dat op de werf bevindt. De
                  klant neemt voor dit alles een gegoede verzekering, ook tegen
                  brand- en stormschade, dit in ons voordeel.
                </li>
                <li>
                  Betwistingen worden beslecht voor de rechtbanken van Leuven.
                  Op alles wat niet expliciet in deze voorwaarden is geregeld is
                  het Belgisch recht van toepassing
                </li>
              </ol>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VoorwaardenPage
